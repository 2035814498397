:root {
    --video-play-button-color: var(--color-primary);
}
.embed-responsive-item.video-js {
    /*override video.js inline style*/
    width: 100% !important;
    height: 100% !important;
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -43px;
    margin-left: -43px;
    width: 86px;
    height: 86px;
    color: #fff;
    border-radius: 999px;
    border: 2px solid;
    background: var(--video-play-button-color);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    font-size: 5em;
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:before {
    text-shadow: none;
    line-height: 1.6;
}
.vjs-default-skin.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:focus {
    background: var(--video-play-button-color);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
}
.vjs-default-skin.vjs-default-skin.vjs-has-started .vjs-control-bar {
    background: #57564a;
    background: rgba(87, 86, 74, 0.8);
}
.vjs-default-skin.vjs-default-skin .vjs-play-progress,
.vjs-default-skin.vjs-default-skin .vjs-volume-level {
    background: var(--color-primary);
}
.vjs-default-skin.vjs-default-skin .vjs-slider-handle:before {
    text-shadow: none;
    color: var(--color-secondary);
}
.vjs-default-skin.vjs-default-skin .vjs-load-progress {
    background: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-progress-control {
    font-size: .6em;
}
.vjs-default-skin.vjs-default-skin .vjs-control {
    color: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-control:focus:before,
.vjs-default-skin.vjs-default-skin .vjs-control:hover:before {
    text-shadow: none;
    opacity: .9;
}
.vjs-default-skin.vjs-default-skin .vjs-volume-bar {
    font-size: .8em;
    background: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-volume-level {
    height: .73em;
}

.vjs-default-skin .vjs-big-play-button.vjs-big-play-button:before {
    left: 6px;
}

@media screen and (max-width: 767px) {
    .vjs-default-skin .vjs-big-play-button.vjs-big-play-button:before {
        line-height: 1.3;
    }
}