.checkbox-styled + .checkbox-styled {
    /*override bootstrap */
    margin-top: 10px;
}

.checkbox-styled input[type="checkbox"] {
    display: none;
}

.checkbox-styled__fake-checkbox {
    content: '';
    display: inline-block;
    width: 21px;
    height: 21px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: content-box;
    text-align: center;
    text-indent: -9999px;
    /*box-shadow: inset 0 0 0 1px var(--color-info);*/
    margin-right: 4px;
    line-height: 20px;
    overflow: hidden;
    vertical-align: middle;
}
.checkbox-styled input[type="checkbox"]:checked + .checkbox-styled__fake-checkbox {
    text-indent: 0;
}
.checkbox-styled--has-category .checkbox-styled__fake-checkbox {
    border-top: none;
    border-right: none;
    border-bottom: none;
    box-shadow: inset 0 0 0 1px var(--color-info);
    border-left-width: 3px;
    border-left-style: solid;
}
.checkbox-styled label {
    padding-left: 0;
}