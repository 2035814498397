:root {
    --console-background: var(--color-primary);
    --console-active-background: var(--color-primary-dark);
}
.console__item {
    display: block;
    position: relative;
    background: var(--console-background);
    color: var(--color-primary-comp);
    margin: 1px 0;
}
.console__icon {
    display: inline-block;
    font-size: 30px;
    line-height: 49px;
    vertical-align: middle;
    color: var(--color-primary-comp);
    cursor: pointer;
}
.console__arrow-icon {
    position: absolute;
    top: 50%;
    line-height: 1;
    right: 10px;
    margin-top: -10px;
}

/*open*/
.console__item.is-active,
.console__item:hover {
    background: var(--console-active-background);
}
.console__item.is-active>.console__icon,
.console__item:hover>.console__icon {
    outline: none;
}
.console__item.is-active>.console__detail,
.console__item:hover>.console__detail {
    display: block;
}
.console__social-icon {
    background: #c3cbd4;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    line-height: 30px;
    font-size: 38px;
}
.console__social-icon .icon {
    vertical-align: middle;
}

.console__social-icon .icon-social_twitter{
    font-size: 21px;
}

.console__social-icon:hover {
    background: var(--color-primary);
}
.console__social-icon .icon-social_instagram {
    font-size: 25px;
}
@media screen and (max-width: 767px) {
    .console {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
        font-size: 1.5em;
    }
    /*.console:after {*/
        /*content: "";*/
        /*display: inline-block;*/
        /*width: 100%;*/
        /*height: 0;*/
        /*line-height: 0;*/
    /*}*/
}
@media screen and (min-width: 768px) {
    .console {
        position: fixed;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 50;
        /*background: #fff;*/
        padding-left: 2px;
    }
    .console__item {
        margin: 2px 0;
        width: 35px;
        height: 35px;
        text-align: center;
        color: inherit;
        box-shadow: 0 0 5px rgba(0,0,0,.15);

    }
    .console__icon {
        width: 100%;
        height: 100%;
        line-height: 34px;
        font-size: 22px;
    }
    .console__detail {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        background: var(--color-info);
        z-index: -1;
        width: 370px;
        text-align: left;
        padding: 35px 60px 35px 40px;
        box-shadow: 0 0 5px rgba(0,0,0,.15);
    }
    .console__item:hover {
        background: var(--console-active-background);
    }
}

@media screen and (min-width: 1200px) {
    .console__item {
        width: 50px;
        height: 50px;
    }
    .console__icon {
        font-size: 30px;
        line-height: 49px;
    }

    .console__icon.icon-konsole_share{
        font-size: 18px;
    }
}