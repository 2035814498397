:root{
    --input-group--rounded-radius: 20px 0 0 20px;
}
/*Form controls*/
.form-control {
    border-radius: 0;
    border: 1px solid #cccccc;
    background: #ffffff;
    color: #595959;
    box-shadow: none;
}
.form-control[readonly] {
    cursor: pointer;
    background: #ffffff;
}

/*Form controls with icons*/
.form-control-wrapper {
    position: relative;
}
.form-control-wrapper--with-icon>.form-control {
    padding-right: 40px;
}
.form-control-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    font-size: 17px;
    margin-top: -8px;
}

/*Form width*/
.form-control--narrow {
    display: inline-block;
    max-width: 55px;
}
select.form-control--narrow {
    text-align: center;
    max-width: 60px;
    padding-right: 5px;
}

/*Placeholders*/
.form-control::-webkit-input-placeholder {  color: inherit; padding-top: 2px; }
.form-control:-moz-placeholder {            color: inherit; }
.form-control::-moz-placeholder {           color: inherit; }

/*Input Groups*/
.input-group-btn {
    font-size: 1rem;
}
.input-group--rounded > .form-control:first-child,
.input-group--rounded > .form-control-wrapper:first-child>input {
    border-radius: var(--input-group--rounded-radius);
}

@media screen and (min-width: 768px) {
    .form-control {
        font-size: calc(15/16em);
    }
}

/*States*/
.has-error .form-control {
    border-color: var(--color-error);
}
.has-error label {
    color: var(--color-error);
}
.has-success .form-control {
    border-color: inherit;
}

/*Parsley*/
.parsley-errors-list {
    display: none;
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
    color: var(--color-error);
}
.parsley-errors-list.filled {
    display: block;
}

.webshop-item .parsley-errors-list {
    font-size: 12px;
}

.voucher-input {
    width: 65px;
}
.voucher-input::-webkit-outer-spin-button,
.voucher-input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.voucher-input {
    -moz-appearance:textfield;
}

.job-file__upload-delete {
    display: flex;
    align-items: center;
}