.footer{
    font-size: 0.9375em;
    overflow: hidden;
}
.footer a:hover {
    text-decoration: underline;
}

.bg-footer{
    background-color: var(--color-secondary)
}

.round-helper {
    height: 114px;
    position: relative;
    width: 105%;
    bottom: -77px;
    left: -50px;
    border-bottom-left-radius: 1000px 115px;
    border-bottom-right-radius: 1000px 115px;
    background: var(--color-primary);
    box-shadow: 80px -8px 0px 0px var(--color-primary);
    margin-top: -105px;
}
.round-helper:before {
    height: 114px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 28px;
    border-bottom-left-radius: 1000px 115px;
    border-bottom-right-radius: 1000px 115px;
    background: var(--color-info);
    box-shadow: 80px -11px 0px 0px var(--color-info);
    content: '';
    width: 100%;
}
.footer__copyright {
    min-height: 80px;
    overflow: hidden;
}
.footer__copyright .list-inline {
    padding-left:30px;
}
@media screen and (max-width: 767px) {
    .footer__col {
        margin-top: 15px;
        float: none;
    }
    .footer__email {
        width: 200px;
    }
}
@media screen and (min-width: 768px) {
    .footer-wrapper {
        position: relative;
        padding-top: 50px;
    }
}
.footer__logo-bar {
    position: relative;
    z-index: 1;
    min-height: 10px;
}