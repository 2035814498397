.weather-icon {
    height: 100px;
    background-size: contain;
}
.weather-icon-sm {
    height: 80px;
    background-size: contain;
}
.text-winter {
    color: #75ccf5;
    vertical-align: text-bottom;
}
@media screen and (min-width: 767px){
    .weather-icon {
        background-position: center;
    }
}

@media screen and (max-width: 767px){
    .weather-icon--center {
        background-position: 50% 0;
    }
}