.kartrace__btn {
    position: absolute;
    left: 0px;
    bottom: 0px;
}
@media screen and (min-width: 768px){
    .col-center {
        float: none;
        display: inline-block;
    }
}
.kartrace__background.kartrace__background {
    bottom: 0px;
    left: 0px;
    opacity: 0.7;
}