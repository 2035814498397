.slide--highlight {
    background-color: #ffed00;
    background: linear-gradient(to bottom, #ffed00 0, #fecb00 100%);
    position: relative;
}
.slide--highlight__background {
    position: absolute;
    bottom: -90px;
    left: 20px;
    opacity: .3;
}
.slide--highlight .btn {
    border-radius: 0px;
    color: var(--color-secondary);
}
.slide--highlight .btn > span {
    vertical-align: middle;
}
.slide--highlight .box--xs {
    min-height: 80px;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .slide--highlight .box--xs {
        min-height: 190px;
        margin-bottom: 20px;
    }
}