:root {
    --btn-ca-display: none;
}

.btn-cta-wrapper {
    display: var(--btn-ca-display);
    position: static;
    margin-top: var(--navbar-border-width);
}
.btn-cta {
    position: relative;
    border-radius: 0;
    color: #ffffff;
    padding: 10px;
    background: #d4021d;
    overflow: visible;
    font-size: 18px;
}

.btn-cta__icon {
    display: inline-block;
    margin-left: 10px;
}

/*hover*/
.btn-cta:hover {
    background: var(--color-primary);
    color: var(--color-primary-comp);
}
.btn-cta:hover:after {
    border-color: transparent transparent var(--color-primary);
}

@media screen and (min-width: 768px) {
    .btn-cta {
        font-size: 18px;
        height: 62px;
        padding: 13px 24px 13px 15px;
    }
    .btn-cta:after {
        content: '';
        position: absolute;
        right: 99.6%; /*not 100% to prevent retina displays from one pixel space*/
        top: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 62px 12px;
        border-color: transparent transparent #d4021d transparent;
    }
    .btn-cta__icon {
        border: 2px solid;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        line-height: 26px;
        vertical-align: baseline;
    }
    .btn-cta-wrapper {
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
    }
    .img-slide--big .btn-cta-wrapper {
        bottom: 17.4%;
    }
}
@media screen and (min-width: 1200px) {
    .btn-cta {
        font-size: 20px;
    }
}

@media screen and (max-width: 767px) {
    .btn-cta {
        position: static;
        display: block;
    }
}