.star-rating {
    display: inline-block;
    font-size: 20px;
}
.star-rating--interactive {
    overflow: hidden;
}

.star-rating--interactive > .star-rating__item {
    float: right;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .star-rating--interactive > .star-rating__item {
        font-size: 30px;
        padding-right: 5px;
    }
}

.star-rating--interactive > .star-rating__item.is-active,
.star-rating--interactive > .star-rating__item.is-active ~ .star-rating__item {
    color: #fabb21;
    text-shadow: none;
}
.star-rating__item,
.star-rating--interactive:hover > .star-rating__item.star-rating__item {
    /*default star styling*/
    color: #ffffff;
    text-shadow:    1px 0 0 var(--color-info),
                    0 1px 0 var(--color-info),
                    -1px 0 0 var(--color-info),
                    0 -1px 0 var(--color-info),
                    -1px 1px 0 var(--color-info),
                    1px -1px 0 var(--color-info),
                    1px 1px 0 var(--color-info),
                    -1px -1px 0 var(--color-info);
}
.star-rating__item--filled,
.star-rating--interactive:hover > .star-rating__item:hover,
.star-rating--interactive:hover > .star-rating__item:hover ~ .star-rating__item {
    color: #fabb21;
    text-shadow: none;
}