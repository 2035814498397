.popover {
    max-width: 600px;
    border: none;
    box-shadow: 0 0 15px rgba(0,0,0,.15);
    border-radius: 0;
}
.popover-content {
    padding: 25px 30px 15px 30px;
}
.popover.bottom>.arrow {
    border-bottom-color: transparent;
}