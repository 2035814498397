.label {
    border-radius: 0;
    font-weight: normal;
    font-size: calc(15em / 16);
    padding: 0.45em .6em 0.35em;
}
.label--top-left {
    position: absolute;
    top: 0;
    left: 0;
}
.label--rotated {
    font-size: 15px;
    white-space: normal;
    display: block;
    padding: 40px;
    width: 115px;
    padding-top: 100px;
    padding-bottom: 6px;
    transform: rotate(-45deg) translateX(6px) translateY(-100px);
    /*transform-origin: bottom center;*/
    text-align: center;
}
.label--rotated__horizontal {
    display: inline-block;
    transform: rotate(45deg);
}