.content-slide {
    position: relative;
}
.content-slide__content {
    text-align: center;
}
@media screen and (min-width: 768px) {
    .content-slide__content {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
        text-align: left;
    }
}
.content-slide__hl {
    font-size: 30px;
    color: var(--color-primary);
}
@media screen and (min-width: 768px) {
    .content-slide__hl {
        color: #fff;
        font-size: 65px;
    }
}
.content-slide__content--full-height {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
