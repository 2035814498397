/*Datepicker*/
.ui-datepicker {
    background: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
}

.ui-datepicker td a,
.ui-datepicker td span {
    text-align: center;
}

.ui-datepicker .ui-state-hover,
.ui-datepicker .ui-state-highlight,
.ui-datepicker .ui-state-active {
    background: var(--color-primary);
    color: var(--color-primary-comp);
}

.ui-datepicker .ui-state-disabled {
    color: #dddddd;
}

.ui-datepicker-next,
.ui-datepicker-prev {
    text-align: center;
    line-height: 1.8em;
    cursor: pointer;

    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ui-datepicker-prev:before {
    content: "\e004";
}

.ui-datepicker-next:after {
    content: "\e005";
}