.expandable-panel__header-text > .sub {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

@media screen and (min-width: 768px) {
    .expandable-panel__header-text {
        color: var(--color-body-text);
    }
}

.expandable-panel__icon .icon-anlagen {
    font-size: 50px;
}