.infoBox {
    overflow: hidden;
    background: #fff;
    padding: 15px;
    padding-left: 55px;
    padding-top: 15px;
    font-size: 1rem;
    font-family: var(--font-default-family);
    font-weight: var(--font-default-weight);
    color: var(--color-body-text);
    box-shadow: 0 0 15px rgba(0,0,0,.15);
    
}