.navbar-nav-main>li,
.navbar__item {
    position: relative;
}
.main-nav__dropdown {
    display: none;
    font-size: 16px;
    padding: 5px 0;
}
.is-open>.main-nav__dropdown {
    display: block;
}
.main-nav__dropdown ul {
    list-style-type: none;
    padding-left: 0;
}
.main-nav__dropdown ul>li {
    padding-left: 1em;
}
.main-nav__dropdown ul>li>a {
    display: inline-block;
    padding: 10px 20px;
    text-indent: -1em;
}
.main-nav__dropdown ul>li>a:hover,
.main-nav__dropdown ul>li>a:focus,
.main-nav__dropdown ul>li>a.active{
    color: var(--color-primary);
}

.main-nav__dropdown ul>li>a:before {
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    content: "\E006";
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
}

/*Wide dropdown*/
.navbar-nav-main>.wide-dropdown-wrapper {
    position: static;
}
.wide-dropdown-wrapper>.main-nav__dropdown {
    right: 0;
    white-space: normal;
    margin-top: var(--navbar-border-width);
}

@media screen and (min-width: 768px) {
    .main-nav__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 18px 40px 18px 30px;
        white-space: nowrap;
        background: #f3f6f8;
        background: linear-gradient(to bottom,  var(--color-info) 0%,rgba(255,255,255,1) 150px);
        box-shadow: 0 0 5px rgba(0,0,0,.25);
    }
    ul>li:last-child > .main-nav__dropdown {
        left: auto;
        right: 0;
    }
    .main-nav__dropdown ul>li>a {
        padding: 5px 0;
    }
    .navbar__item:hover>.main-nav__dropdown,
    li:hover>.main-nav__dropdown {
        display: block;
    }
}