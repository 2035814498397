.img-slide {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-top: calc(330*100%/450);
}

.img-slide .owl-controls {
    display: none;
}
.img-slide__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: auto;
}
.img-slide__content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
}
.img-slide__content--bottom {
    top: auto;
    bottom: 0;
}
.img-slide__content--full-height {
    top: 0;
    bottom: 0;
}
.img-slide__hl {
    font-size: 20px;
    text-shadow: 0 0 15px rgba(0,0,0,.5);
    line-height: 1;
}
.img-slide__hl>strong {
    font-size: 35px;
    display: block;
}

@media screen and (min-width: 768px) {
    .img-slide {
        padding-top: 29.7619%;
    }
    .img-slide .owl-controls {
        z-index: 2;
        display: block;
        position: absolute;
        bottom: 19%;
        right: 0;
        left: 0;
    }
    .img-slide .owl-dots {
        max-width: 1240px;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        margin: auto;
    }
    .img-slide .owl-dot {
        display: inline-block;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        line-height: 30px;
        text-align: center;
        background: var(--color-primary);
        color: var(--color-primary-comp);
        margin: 0;
        margin-right: 10px;
        position: relative;
    }
    .img-slide .owl-dot.active,
    .img-slide .owl-dot:hover,
    .img-slide .owl-dot:focus {
        background: var(--color-secondary);
        color: var(--color-primary);
    }
    .img-slide--big {
        padding-top: 41.66666%;
    }
    .img-slide__hl {
        font-size: 42px;
    }
    .img-slide__hl>strong {
        font-size: 60px;
    }
    #header-slider:after {
        width:425px;
        height:174px;
        background-size:426px 175px;
    }
}

#header-slider:after {
    content:'';
    position: absolute;
    right:0;
    bottom:0;
    width:213px;
    height:86px;
    pointer-events: none;
    background: url(/static/img/sdt-overlay.png) 0 0 no-repeat;
    background-size:213px 87px;
}
@media screen and (min-width: 1200px) {
    .img-slide__hl {
        font-size: 55px;
    }
    .img-slide__hl>strong {
        font-size: 70px;
    }
}

.owl-carousel {
    z-index: auto;
}

.owl-carousel .owl-item {
    overflow: hidden;
}

.badge-360.badge-360.badge-360 {
    /*override owl styling*/
    width: auto;
}

.owl-carousel .owl-item img {
    height: auto;
}

.js-headerSlider__item + .js-headerSlider__item{
    display: none;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    #header-slider{
        top: 50px;
    }
}