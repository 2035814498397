.search-result {
    border-bottom: 1px solid var(--color-info);
}
.search-result:hover .search-result__header>a {
    text-decoration: underline;
}
.search-result__body {
    font-size: calc(15em / 16);
}

@media screen and (min-width: 768px) {
    .search-result__body {
        padding-left: 20px;
        padding-right: 20px;
    }
}