:root {
    --color-bonus: #0483ae;
}

/*helper sommercard*/
.bg-bonus {
    background: var(--color-bonus);
    color: #ffffff;
}

/*Sommercard Categories*/
.bc-bergbahnen { border-color: #009ee0;}
.bc-mautstrassen { border-color: #547483;}
.bc-verkehrsmittel { border-color: #a59ec3;}
.bc-badeseen-baeder { border-color: #fabb21;}
.bc-museen { border-color: #cf93a5;}
.bc-freizeit { border-color: #a7c225;}
.bc-kinderprogramm { border-color: #c00418;}
.bc-sport-natur { border-color: #005c24;}
.bc-shop-verleih { border-color: #003c96;}
.bc-beauty-kino { border-color: #00dcd2;}

/*Sommercard Slide*/
.sommercard-slide__bg {
    position: relative;
}
.sommercard-slide__bg:before {
    content: '';
    position: absolute;
    background: url("/static/img/dachstein/backgrounds/bg-faecher-green.png");
    background-size: cover;
    background-position: center;
    bottom: 99.5%; /*prevent 1px space on retina devices*/
    left: 0;
    right: 0;
    padding-top: 8.7%; /*calc(146 * 100% / 1680);*/
}
.sommercard-slide__container {
    position: relative;
    top: -20px;
}
.sommercard-slide__badge {
    position: absolute;
    top: 40px;
    right: 25px;
    width: 135px;
    height: 135px;
    padding: 16px 10px 0 10px;
    border: 3px solid;
    border-radius: 100%;
    transform: rotate(10deg);
    box-shadow: 0 0 8px rgba(0,0,0,.5);
}
.sommercard-slide__badge-hr {
    background: var(--color-primary-comp);
    height: 2px;
    width: 55%;
    margin: 3px auto;
    border: none;
}
@media screen and (max-width: 767px) {
    .sommercard-slide {
        height: auto;
        padding-top: 0;
    }
    .sommercard-slide .img-slide__content,
    .sommercard-slide .img-slide__background {
        position: relative;
    }
    .sommercard-slide__benefits {
        margin-top: 20px;
    }
}
@media screen and (min-width: 768px) {
    .sommercard-slide__bg {
        height: 270px;
    }
    .sommercard-slide__container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 25px;
        padding-bottom: 25px;
    }
    .sommercard-slide__img-wrapper {
        position: absolute;
        bottom: 0;
        right: 11%;
        width: 250px;
        padding: 0;
    }
    .sommercard-slide__benefits>li {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .sommercard-slide__img {
        height: auto;
        max-width: 100%;
    }
    .sommercard-slide__badge {
        padding-top: 22px;
        top: auto;
        bottom: 59.1%;
        right: 70%;
        width: 150px;
        height: 150px;
        border: 5px solid;
        box-shadow: 0 0 15px rgba(0,0,0,.5);
    }
    li.sommercard-slide__benefit-1 {
        bottom: calc(325*100%/583);
        left: calc(148*100%/383);
    }
    li.sommercard-slide__benefit-2 {
        bottom: calc(268*100%/583);
        left: calc(102*100%/383);
    }
    li.sommercard-slide__benefit-3 {
        bottom: calc(210*100%/583);
        left: calc(119*100%/383);
    }
    li.sommercard-slide__benefit-4 {
        bottom: calc(154*100%/583);
        left: calc(159*100%/383);
    }
    li.sommercard-slide__benefit-5 {
        bottom: calc(97*100%/583);
        left: calc(131*100%/383);
    }
    li.sommercard-slide__benefit-6 {
        bottom: calc(38*100%/583);
        left: calc(92*100%/383);
    }
}
@media screen and (min-width: 1200px) {
    .sommercard-slide__img-wrapper {
        width: 270px;
    }
    .sommercard-slide__container {
        /*top: -130px;*/
    }
    .sommercard-slide__badge {
        width: 180px;
        height: 180px;
    }
}
@media screen and (min-width: 1300px) {
    .sommercard-slide__img-wrapper {
        width: 300px;
        right: 7%;
    }
}
@media screen and (min-width: 1400px) {
    .sommercard-slide__img-wrapper {
        width: 333px;
        right: 5%;
    }
}
@media screen and (min-width: 1600px) {
    .sommercard-slide__img-wrapper {
        width: auto;
        right: 2.8333333%;
    }
}
/*End: Sommercard Slide*/

