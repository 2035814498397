:root {
    --main-background: linear-gradient(to bottom,  var(--color-info) 0%, #ffffff 700px);
    --color-primary-dark: color(var(--color-primary) b(+20%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
/*Text Helper*/
.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-bottom {
    vertical-align: bottom;
}
.text-crossed {
    position: relative;
}
.text-crossed:before,
.text-crossed:after {
    content: '';
    position: absolute;
    top: -3px;
    bottom: -1px;
    left: 50%;
    border-left: 1px solid;
    transform: rotate(-45deg);
}
.text-crossed:after {
    transform: rotate(45deg);
}
.text-underline.text-underline {
    text-decoration: underline;
}

.icon-in-text {
    font-size: 0.8125em;
    vertical-align: -.07em;
    margin-right: 6px;
}

/*Text color*/
.text-primary { color: var(--color-primary); }
a.text-primary { color: var(--color-primary); }
a.text-primary:hover { color: var(--color-primary-dark); }

.text-secondary { color: var(--color-secondary); }
a.text-secondary { color: var(--color-secondary); }
a.text-secondary:hover { color: var(--color-secondary); }

.text-default { color: var(--color-body-text) }

.text-white { color: #ffffff; }

.text-info {
    color: var(--color-dark-grey);
}

/*Backgrounds*/
.bg-primary {
    background-color: var(--color-primary);
}
.bg-secondary {
    background-color: var(--color-secondary)
}
.bg-primary-light {
    background-color: var(--color-primary-light);
}
.bg-info { background-color: var(--color-info); }

a.bg-info:hover {
    background-color: #DADEDF;
}


.bg-info-darker { background-color: var(--color-info-dark); }
.bg-info-light { background-color: var(--color-info-light); }

.bg-success { 
    background-color: var(--color-success);
    color: #ffffff;
}
.bg-danger {
    background-color: var(--color-error);
    color: #ffffff;
}
.bg-warning {
    background-color: var(--color-warning);
    color: #ffffff;
}
@media screen and (min-width: 768px) {
    .bg-main {
        background-color: #ffffff;
        background-image: var(--main-background);
        background-repeat: no-repeat;
    }
}
.bg-info-gradient {
    background-color: var(--color-info);
    background: linear-gradient(to bottom,  var(--color-info) 0%, #ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-info)', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.bg-white {
    background-color: #ffffff;
}
.bg-shine {
    background-image: radial-gradient(ellipse at 62% 31%,rgba(229,229,229,.4) 0,rgba(229,229,229,.2) 20%,rgba(229,229,229,0) 30%);
}


/*Aspect ractions*/
.embed-responsive { width: 100%; }
.embed-responsive.embed-responsive-16by11 {
    padding-top: 68.75%;
}
.embed-responsive-25by14 {
    padding-top: calc(14/25*100%);
}
.embed-responsive.embed-responsive-2by1 {
    padding-top: calc(1/2*100%);
}
.embed-responsive.embed-responsive-3by2 {
    padding-top: calc(2/3*100%);
}
.embed-responsive.embed-responsive-4by1 {
    padding-top: calc(1/4*100%);
}
.embed-responsive.embed-responsive-1by1 {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-9by5 {
    padding-top: 56.1%;
}
.embed-responsive.embed-responsive-19by7 {
    padding-top: 36.5%;
}

/*Row gutters (mobile)*/
.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0>.col,
.row--gutter-width-0>.arrange>.col {
    padding-left: 0;
    padding-right: 0;
}
.row--gutter-width-10 {
    margin-left: -10px;
    margin-right: -10px;
}
/*.row--gutter-width-24>.col {*/
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
/*}*/
.row--gutter-width-24 {
    margin-left: -6px;
    margin-right: -6px;
}
.row--gutter-width-24>.col,
.row--gutter-width-24>.arrange>.col {
    padding-left: 6px;
    padding-right: 6px;
}
/*Row gutters (not mobile)*/
@media screen and (min-width: 768px) {
    .row--gutter-width-24 {
        margin-left: -12px;
        margin-right: -12px;
    }
    .row--gutter-width-24>.col,
    .row--gutter-width-24>.arrange>.col {
        padding-left: 12px;
        padding-right: 12px;
    }
}
.row--gutter-width-40 {
    margin-left: -20px;
    margin-right: -20px;
}
.row--gutter-width-40>.col {
    padding-left: 20px;
    padding-left: 20px;
}
.row--inline-block>.col {
    text-align: left;
}
@media screen and (min-width: 768px) {
    .row--inline-block {
        /*remove whitespace*/
        font-size: 0;
        text-align: center;
    }
    .row--inline-block>.col {
        display: inline-block;
        float: none;
        font-size: 16px;
        font-size: 1rem;
        vertical-align: top;
        text-align: left;
    }
}
/*row same height*/
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
.row--same-height,
.row--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /*align-items: stretch;*/
    /*justify-content: space-between;*/
    /*overflow: hidden;*/
}
.row--same-height {
    flex-wrap: wrap;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}
.row--same-height > .col,
.row--same-height.slick-slider .col {
    display: flex;
    flex-direction: column;
    height: auto;
}


/*JS-specific*/
.isClickable,
.js-lightbox {
    cursor: pointer;
}

.js-lightbox {
    display: block;
}

/*Arrange*/

.arrange-xs {
    display: table;
    min-width: 100%;
    table-layout: auto;
}

.arrange-xs > .arrange__item {
    display: table-cell;
    vertical-align: middle;
    float: none;
}
.arrange-xs > .arrange__item--top {
    vertical-align: top;
}
.arrange-xs > .arrange__item--bottom {
    vertical-align: bottom;
}


@media screen and (min-width: 768px) {
    .arrange {
        display: table;
        min-width: 100%;
        table-layout: auto;
    }
    .arrange.row {
        /*width: auto;*/
    }
    .arrange__item {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }
    .arrange__item--top {
        vertical-align: top;
    }
    .arrange__item--bottom {
        vertical-align: bottom;
    }
}

/*Centering Helper*/
.center-container {
    text-align: center;
    font-size: 0;
    /*padding: 5px;*/
}
.center-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.center-container__item {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
}

.absolute-centered {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

/*Margins (mobile)*/
.no-margin {
    margin: 0;
}
/*Margins (mobile)*/
.mt0 { margin-top: 0; }
.mt10 { margin-top: 5px; }
.mt20 { margin-top: 10px; }
.mt30 { margin-top: 15px; }
.mt45 { margin-top: 22px; }

.mr5 { margin-right: 5px; }
.mr15 { margin-right: 15px; }

.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 3px; }
.mb10 { margin-bottom: 5px; }
.mb20 { margin-bottom: 10px; }


.ml30 { margin-left: 15px; }
.ml15 { margin-left: 15px; }

/*Margins (not mobile)*/
@media screen and (min-width: 768px) {
    .mt10 { margin-top: 10px; }
    .mt20 { margin-top: 20px; }
    .mt30 { margin-top: 30px; }
    .mt45 { margin-top: 45px; }

    .mb5 { margin-bottom: 5px; }
    .mb10 { margin-bottom: 10px; }
    .mb20 { margin-bottom: 20px; }

    .ml30 { margin-left: 30px; }
}


/*Paddings*/
.no-padding { padding: 0; }
.pt0 { padding-top: 0 !important; }
/*.pl5 { padding-left: 5px; }*/
.pt10 { padding-top: 10px; }
.pl5 { padding-left: 5px; }
.pb10 { padding-bottom: 10px; }
.pb20 { padding-bottom: 20px; }
.pl0 { padding-left: 0; }
.pr0 { padding-right: 0; }
.pb0 { padding-bottom: 0; }



/*Display*/
.d-b { display: block; }
.d-ib { display: inline-block; }

/*Image Helper*/
.img-full-width {
    width: 100%;
    height: auto;
}

.list--high>li {
    margin-bottom: 1em;
}
.list-inline { margin-bottom: 0; }
.list-inline>li { margin-bottom: 10px; }

.list-inline--gutter-70 {
    margin-left: -15px;
}
.list-inline--gutter-70>li {
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (min-width: 1200px) {
    .list-inline--gutter-70 {
        margin-left: -35px;
    }
    .list-inline--gutter-70>li {
        padding-left: 35px;
        padding-right: 35px;
    }
}
.list-inline--gutter-20 {
    margin-left: -10px;
}
.list-inline--gutter-20>li {
    padding-left: 10px;
    padding-right: 10px;
}
.list--middel>li {
    vertical-align: middle;
}
.list--bottom>li {
    vertical-align: bottom;
}
@media screen and (max-width: 767px) {
    .list-inline-xs {
        list-style-type: none;
        padding-left: 0;
        margin-left: -5px;
        margin-right: -5px;
    }
    .list-inline-xs>li{
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }
}
/*For: List default base see wysiwyg.css*/
ul.list-default--secondary>li:before {
    color: var(--color-secondary);
}
ul.list-default--white>li:before {
    color: #ffffff;
}

/*Columns*/
.columns-2 {
    columns: 2;
}

/*Shapes*/
.shape-square {
    display: inline-block;
    width: 21px;
    height: 21px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
}

/*Border*/
.border-top {
    border-top: 1px solid var(--color-info-dark);
}
.border-bottom{
    border-bottom: 1px solid var(--color-info-dark);
}
.border-bottom--double {
    position: relative;
}
.border-bottom--double:after {
    content: '';
    display: block;
    margin-top: 2px;
    border-bottom: 1px solid var(--color-info-dark);
}
@media screen and (min-width: 767px) {
    .border-right {
        border-right: 1px solid var(--color-info-dark);
    }
}

/*Divider*/
.divider.bg-primary {
    border-bottom: 1px solid var(--color-primary);
}

.lh-1 {
    line-height: 1;
}