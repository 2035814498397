.social-mashup__no-border .panel-body {
    border: 0px;
}
.social-mashup .panel-heading {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
/*Social mashup Panels*/
.facebook-heading {
    background-color: #3b5998;
    color: white;
}
.youtube-heading {
    background-color: #c4302b;
    color: white;
}
.google-plus-heading {
    background-color: #dd4b39;
    color: white;
}
.instagram-heading {
    background-color: #3a6289;
    color: white;
}
.social-mashup__icon {
    font-size: 65px;
    line-height: 57px;
}
.youtube-subscribe > div {
    vertical-align: middle!important;
    background: white !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 5px !important;
    height: 34px !important;
    width: 97px !important;
}
.youtube-subscribe.hidden-xs > div {
    width: 127px !important;
}
.fb-like-box, .fb-like-box > span, .fb-like-box > span > iframe{
    width: 100% !important;
}
.mashup-instagram .col-sm-3 {
    margin-bottom: 10px;
}
.mashup-google-plus{
    max-width: 450px;
}
.mashup-blog .panel-body {
    background-image: url("/static/img/dachstein/backgrounds/blog-bg.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100% auto;
    padding-bottom: 28%;
}
.btn-instagram {
    background-color: #5583a7;
    border: 2px solid #fff;
    border-radius: 2px;
    padding: 4px 20px;
    min-height: 20px;
}