.table,
.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin: 0;
}
.wysiwyg table {
    margin: 25px 0;
}
.table th,
.table td,
.wysiwyg table th,
.wysiwyg table td {
    text-align: center;
    padding: 6px;
    line-height: 1.42857143;
    vertical-align: top;
}

.table th,
.wysiwyg table th {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

.table > tbody > tr > td,
.wysiwyg table > tbody > tr > td,
.table > tbody > tr > th,
.wysiwyg table > tbody > tr > th,
.table > tfoot > tr > td,
.wysiwyg table > tfoot > tr > td,
.table > tfoot > tr > th,
.wysiwyg table > tfoot > tr > th,
.table > thead > tr > td,
.wysiwyg table > thead > tr > td,
.table > thead > tr > th,
.wysiwyg table > thead > tr > th {

}
@media screen and (min-width: 768px) {
    .table > tbody > tr > td,
    .wysiwyg table > tbody > tr > td,
    .table > tbody > tr > th,
    .wysiwyg table > tbody > tr > th,
    .table > tfoot > tr > td,
    .wysiwyg table > tfoot > tr > td,
    .table > tfoot > tr > th,
    .wysiwyg table > tfoot > tr > th,
    .table > thead > tr > td,
    .wysiwyg table > thead > tr > td,
    .table > thead > tr > th,
    .wysiwyg table > thead > tr > th {
        padding: 10px 15px;
    }
}
.table > thead > tr > th,
.wysiwyg table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 0;
    background: var(--color-primary);
    color: #ffffff;
}
.table > caption + thead > tr:first-child > td,
.wysiwyg table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.wysiwyg table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.wysiwyg table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.wysiwyg table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.wysiwyg table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.wysiwyg table > thead:first-child > tr:first-child > th {
    border-top: 0;
}
.table > tbody > tr:nth-child(odd) > td,
.wysiwyg table > tbody > tr:nth-child(odd) > td,
.table > tbody > tr:nth-child(odd) > th,
.wysiwyg table > tbody > tr:nth-child(odd) > th {
    background-color: #eaeff4;
    background-color: rgba(0,0,0,.035);
}
.table > tbody > tr > td,
.wysiwyg table > tbody > tr > td,
.table > tbody > tr > th,
.wysiwyg table > tbody > tr > th {
    border-left: 1px solid #dddddb;
    border-top: 0;
    vertical-align: middle;
}
.table > tbody > tr > td:first-child,
.wysiwyg table > tbody > tr > td:first-child,
.table > tbody > tr > th:first-child,
.wysiwyg table > tbody > tr > th:first-child {
    border-left: none;
}
/*Modifiers*/
.thead--grey.thead--grey >tr >th {
    background: #b7bec7;
    border-right-color: #dddddb;
}
.thead--grey-light.thead--grey-light >tr >th {
    background: #dae3ec;
    color: inherit;
}
.table-hover.table-hover>tbody>tr:hover>td {
    background-color: #dae3ec;
}
.table--text-left th, .table--text-left td {
    text-align: left;
}
.table--no-border > tbody > tr > td,
.table--no-border > tbody > tr > th {
    border: none;
}