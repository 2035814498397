@media screen and (min-width: 768px) {
    .lightbox-item-container {
        position: relative;
    }

    .lightbox-item-container__hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(87, 86, 74, 0.5);
        visibility: hidden;
        transition: opacity 300ms;
    }

    .lightbox-item-container__icon {
        color: #ffffff;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 60px;
        margin-top: -30px;
    }

    .lightbox-item-container__icon:before {
        line-height: 1;
    }

    .js-lightbox:hover .lightbox-item-container__hover {
        visibility: visible;
    }
}

.js-lightbox {
    cursor: pointer;
}

.mfp-content {
    background: #ffffff;
    margin-top: 20px;
    padding: 0 4.76%;
}
.mfp-container {
    padding: 0;
}
@media screen and (min-width: 768px) {
    .mfp-container {
        padding: 0 20px;
    }
}

.mfp-figure:after {
    display: none;
}
/*override magnific popup*/
.mfp-close.mfp-close.mfp-close {
    position: absolute;
    top: -18px;
    right: 0;
    width: 36px;
    height: 36px;
    background: #b2b2b2;
    border-radius: 100px;
    text-align: center;
    left: auto;
    padding: 0;
    opacity: 1;
    will-change: transform;
    transform: scale(1);
    transition: transform 120ms ease;
}

@media screen and (min-width: 768px) {
    .mfp-close.mfp-close.mfp-close {
        right: -18px;
    }
}
.mfp-close.mfp-close.mfp-close:hover {
    transform: scale(1.1);
}

.mfp-close > .icon {
    position: static;
    display: block;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    padding: 0;
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    right: 0;
}
.mfp-image-holder .mfp-content {
    min-width: 300px;
}

.mfp-inline-holder .mfp-content {
    width: auto;
    max-width: 100%;
}

.mfp-bottom-bar {
    position: static;
    text-align: center;
    padding-bottom: 30px;
}

.mfp-counter {
    position: static;
    color: inherit;
    font-size: inherit;
    padding-bottom: 5px;
}
.mfp-title {
    padding-right: 0;
    margin-top: 15px;
    text-align: center;
    color: inherit;
}
.mfp-asset-video .mfp-content {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-asset-video .mfp-close {
    top: -5px;
    height: auto;
    width: auto;
}

.mfp-iframe-holder .mfp-close {
    top: 0;
}

.mfp-iframe-holder .mfp-content {
    padding: 40px 4.7%;
    max-width: 1140px;
}

.mfp-iframe-scaler {
    position: relative;
    padding-top: 64.25%;
    overflow: visible;
}

.mfp-webcam .mfp-content {
    max-width: 660px;
    padding-left: 30px;
    padding-right: 30px;
}

.mfp-iframe-scaler--webcam {
    padding-top: 130%;
}

.mfp-close > .icon {
    pointer-events: none;
}

.mfp-arrow:after {
    display: none;
}

.mfp-arrow.icon {
    font-size: 50px;
    color: #ffffff;
    width: 40px;
    height: 80px;
    padding: 0;
    margin-top: -40px;
    z-index: 1045;
    background: var(--color-primary);
    border-radius: 0 80px 80px 0;
    opacity: 1;
    will-change: transform;
    transform: scale(1);
    transition: transform 120ms ease;
    transform-origin: left center;
}
.mfp-arrow.icon:before {
    width: auto;
    height: auto;
    margin: 0;
    border: none;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -29px;
}
.mfp-arrow.mfp-arrow-right.icon {
    border-radius: 80px 0 0 80px;
    transform-origin: right center;
}
.mfp-arrow.mfp-arrow-right.icon:before {
    left: auto;
    margin-left: 0;
    right: 50%;
    margin-right: -29px;
}
.mfp-arrow.icon:hover {
    cursor: pointer;
    opacity: 1;
    transform: scale(1.03);
}

.mfp-image-holder .mfp-figure {
    max-width: 1000px;
}

.mfp-zoom-out-cur {
    cursor: default;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}
.mfp-preloader {
    background: white;
    height: 281px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    line-height: 281px;
    margin-top: -140px;
}