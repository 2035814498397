.interactive-map--regions a path,
.interactive-map--regions a polygon {
    fill: var(--color-primary);
    opacity: 0;
    transition: 300ms ease;
}
.interactive-map--regions a path:hover,
.interactive-map--regions a polygon:hover {
    opacity: 1;
}
.interactive-map--regions a.active path,
.interactive-map--regions a.active polygon {
    opacity: 1;
}