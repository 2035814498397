.google-map {
    position: relative;
}
.google-map__controls {
    position: absolute;
    top: 9px;
    left: 8px;
}
.google-map__controls > .btn {
    margin-bottom: 10px;
}
