:root {
    --color-primary-dark: color(var(--color-primary) b(+20%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}

.content-teaser.bg-info {
    background: #f5f8f9;
}
.content-teaser.bg-info:hover {
    background-color: var(--color-info);
}
.content-teaser {
    margin-bottom: 20px;
}
.content-teaser-body {
    padding: 10px;
    font-size: 0.9375em;
}

.content-teaser.content-teaser--contact a,
.content-teaser.content-teaser--contact a:hover {
    color: var(--color-primary-comp);
}

/*.content-teaser.content-teaser--contact .icon{
    position: initial;
}
.content-teaser a .icon{
    position: relative;
    top: 2px;
}*/

.content-teaser a{
    color: var(--color-primary);
}

.content-teaser a:hover{
    color: var(--color-primary-dark);
}



/*highlight*/
.content-teaser--highlight {
    border: 2px solid var(--color-primary);
}

@media screen and (min-width: 768px) {
    .content-teaser-body {
        padding-left: 0;
        padding-right: 20px;
    }
}