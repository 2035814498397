
@media screen and (max-width: 767px){

    .hotline-slide--xs{
        margin: 20px 0 -12px;
    }

    .hotline-slide--xs span{
        display: block;
        font-style: italic;
        margin-bottom: 5px;
    }

    .hotline-slide--xs img{
        height: 36px;
    }
}
