:root {
    --nav-tree--active-color: var(--color-primary);
}
.nav-tree__hl.nav-tree__hl {
    border-bottom: 1px solid;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
}
@media screen and (min-width: 1200px) {
    .nav-tree__hl {
        font-size: 22px;
    }
}

.nav-tree>ul {
    padding-left: 0;
    font-size: calc(18em / 16);
}
.nav-tree ul>li {
    margin-bottom: calc(12em / 18);
    text-indent: 0;
}
.nav-tree ul ul {
    margin-top: 1em;
    padding-left: 16px;
    font-size: calc(15em / 18);
}

.nav-tree a:hover,
.nav-tree .active:before,
.nav-tree .active>a {
    color: var(--nav-tree--active-color);
}

.nav-tree .list-arrows li:before{
    position: relative;
    top: 3px;
}

.sidebar .icon{
    position: relative;
    /*top: 2px;*/
}