:root {
    --gallery__caption-color--webcam: var(--color-secondary);
}
.gallery__caption {
    padding-top: 10px;
}
.gallery__caption>.pull-left {
    margin-left: 15px;
    margin-bottom: 10px;
}
.gallery__caption.webcam--caption {
    color: var(--gallery__caption-color--webcam);
}
.gallery__main> .gallery__item + .gallery__item {
    /*only show first item before carousel is initialized*/
    display: none;
}

.gallery__icon{
    position: relative;
    z-index: 1;
    outline: none;
}

.gallery__icon span{
    float: right;
    font-size: 20px;
    padding: 12px;
    background-color: var(--color-primary);
    opacity: 0.7;
    color: #fff;
}
.gallery__icon span:hover{
    opacity: 1;
}

.gallery__main .owl-prev,
.gallery__main .owl-next {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    width: 30px;
    height: 60px;
    line-height: 50px;
    font-size: 40px;
    border-radius: 0 80px 80px 0;
    background: var(--color-primary);
    color: #fff;
    border: 2px solid;
    border-left: 0;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .gallery__main .owl-prev,
    .gallery__main .owl-next {
        width: 40px;
        height: 80px;
        line-height: 65px;
        font-size: 50px;
    }
}
.gallery__main .owl-prev>.icon,
.gallery__main .owl-next>.icon {
    vertical-align: middle;
}
.gallery__main .owl-prev>.icon {
    position: relative;
    left: -10px;
}
.gallery__main .owl-next {
    left: auto;
    right: 0;
    border-radius: 80px 0 0 80px;
    border: 2px solid;
    border-right: 0;
}
.gallery__main .owl-prev:hover,
.gallery__main .owl-next:hover {
    background: var(--color-primary-dark);
}

/*Thumbs*/
.gallery_thumbs {
    position: relative;
    margin-top: 10px;
}
.gallery_thumbs .owl-item {
    background: var(--color-primary);
}
.gallery_thumbs .owl-item img {
    opacity: 1;
    cursor: pointer;
    transition: 300ms opacity;
    -webkit-backface-visibility: hidden;
}
.gallery_thumbs .owl-item.is-active img,
.gallery_thumbs .owl-item img:hover {
    opacity: .4;
}
/*Thumb Controls*/
.gallery_thumbs .owl-prev,
.gallery_thumbs .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 30px;
    color: #ffffff;
}
.gallery_thumbs .owl-prev:before,
.gallery_thumbs .owl-next:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-primary);
    opacity: .6;
}
.gallery_thumbs .owl-prev:hover:before,
.gallery_thumbs .owl-next:hover:before {
    opacity: .8;
}
.gallery_thumbs .owl-prev > .icon,
.gallery_thumbs .owl-next > .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -5px;
    font-size: 20px;
}
.gallery_thumbs .owl-next {
    left: auto;
    right: 0;
}