.footer-wrapper {
    background: var(--color-secondary);
    text-align: center;
}
.footer-wrapper h3,
.footer__copyright {
    color: var(--color-primary);
}
.footer__copyright {
    padding-top: 15px;
}
.footer-sprite {
    background-image: url(/static/img/planai/sitebyelements.png);
    background-repeat: no-repeat;
    display: block;
}
.footer-sprite-site-by-elements {
    width: 147px;
    height: 15px;
    background-position: 0;
}
.round-helper {
    display: none;
}
.footer__copyright {
    min-height: 0;
}