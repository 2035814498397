.panel {
    border-radius: 0;
    border: none;
    box-shadow: none;
}
.panel-primary .panel-heading {
    color:#fff;
    background: var(--color-primary);
    border: none;
    border-radius: 0;
}
.panel-body {
    border: 1px solid var(--color-info-dark);
}