.imb-teaser__bottom,
.img-teaser__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px calc(100% * 20/ 280);
}
.imb-teaser__bottom {
    top: auto;
    bottom: 0;
}