:root {
    --text-transform-heading: normal;
}
html {
    font-size: 13px;
}
@media screen and (min-width: 768px) {
    html {
        font-size: 15px;
    }
}
@media screen and (min-width: 1200px) {
    html {
        font-size: 16px;
    }
}
body {
    font-family: var(--font-default-family);
    font-weight: var(--font-default-weight);
    font-size: 1em;
    color: var(--color-body-text);
}

h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: 0;
}

h1, .h1 { font-size: 25px; }
h2, .h2 { font-size: 19px; }
h3, .h3 { font-size: 17px; }
h5, .h5 { font-size: 15px; }
h6, .h6 { font-size: 1em; }

@media screen and (min-width: 768px) {
    h1, .h1 { font-size: 35px; }
    h2, .h2 { font-size: 25px; }
    h3, .h3 { font-size: 20px; }
    h5, .h5 { font-size: 17px; }
}
@media screen and (min-width: 1200px) {
    h1, .h1 { font-size: 50px; }
    h2, .h2 { font-size: 30px; }
    h3, .h3 { font-size: 25px; }
    h4, .h4 { font-size: 20px; }
    h5, .h5 { font-size: 18px; }
}

h1, .h1,
h2, .h2,
h3, .h3,
h5, .h5,
h6, .h6 {
    display: block;
    line-height: 1.33;
    font-family: var(--font-heading-family);
    font-weight: var(--font-default-weight);
}
h1, .h1,
h2, .h2,
h3, .h3 {
  text-transform: inherit;
}

strong, .strong {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

/*Font-sizes*/
.fz15 { font-size: 13px; }
.fz18 { font-size: 14px; }
.fz20 { font-size: 16px; }
.fz25 { font-size: 20px; }
.fz30 { font-size: 18px; }
.fz40 { font-size: 22px; }
.fz80 { font-size: 40px; }

small, .small {font-size: 90%;}

@media screen and (min-width: 768px) {
    .fz15 { font-size: 14px; }
    .fz18 { font-size: 16px; }
    .fz20 { font-size: 18px; }
    .fz30 { font-size: 22px; }
    .fz40 { font-size: 30px; }
    .fz80 { font-size: 60px; }
}

@media screen and (min-width: 1200px) {
    .fz15 { font-size: 15px; }
    .fz18 { font-size: 18px; }
    .fz20 { font-size: 20px; }
    .fz25 { font-size: 25px; }
    .fz30 { font-size: 30px; }
    .fz40 { font-size: 40px; }
    .fz80 { font-size: 80px; }
}

/* ugly hack incoming - icon was added later and is initially smaller than all others */

.fz25.icon-sessellift_zwei {
    font-size: 26px;
}

@media screen and (min-width: 1200px) {
    .fz25.icon-sessellift_zwei {
        font-size: 32px;
    }
}