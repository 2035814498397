:root {
    --default-margin: 30px;
}
.content-block {
    margin-top: calc(var(--default-margin)/2);
    margin-bottom: calc(var(--default-margin)/2);
}
.content-box {
    padding-top: calc(var(--default-margin)/2);
    padding-bottom: calc(var(--default-margin)/2);
}
.slide {
    padding-top: var(--default-margin);
    padding-bottom: var(--default-margin);
}
.section {
    margin-top: var(--default-margin);
    margin-bottom: var(--default-margin);
}
.box {
    padding: calc(var(--default-margin)/2);
}
.box--md {
    padding: calc(var(--default-margin)/3);
}
.box--xs {
    padding: calc(var(--default-margin)/3);
}

@media screen and (min-width: 768px) {
    .content-block {
        margin-top: calc(var(--default-margin)*2/3);
        margin-bottom: calc(var(--default-margin)*2/3);
    }
    .content-box {
        padding-top: calc(var(--default-margin)*2/3);
        padding-bottom: calc(var(--default-margin)*2/3);
    }
    .slide {
        padding-top: calc(var(--default-margin)*4/3);
        padding-bottom: calc(var(--default-margin)*4/3);
    }
    .section {
        margin-top: calc(var(--default-margin)*4/3);
        margin-bottom: calc(var(--default-margin)*4/3);
    }
    .box {
        padding: calc(var(--default-margin));
    }
    .box--md {
        padding: calc(var(--default-margin)*2/3);
    }
}

@media screen and (min-width: 1200px) {
    .content-block {
        margin-top: var(--default-margin);
        margin-bottom: var(--default-margin);
    }
    .content-box {
        padding-top: var(--default-margin);
        padding-bottom: var(--default-margin);
    }
    .slide {
        padding-top: calc(var(--default-margin)*2);
        padding-bottom: calc(var(--default-margin)*2);
    }
    .section {
        margin-top: calc(var(--default-margin)*2);
        margin-bottom: calc(var(--default-margin)*2);
    }
}