:root {
    --btn-border-radius: 20px;
    --btn-group-secondary-color: var(--color-primary);
}

.btn {
    box-shadow: none;
    border-radius: var(--btn-border-radius);
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
    padding: 9px 14px 7px 14px;

    overflow: hidden;
    position: relative;
    font-size: 1em;
    border: none;
    min-height: 34px;
}
@media screen and (min-width: 768px) {
    .btn {
        padding-top: 6px;
        padding-bottom: 5px;
    }
}
.btn:active {
    box-shadow: none;
}

.btn-lg {
    font-size: 1.125em;
    padding-top: 10px;
    padding-bottom: 8px;
}

/*Icon only button*/
.btn-icon-only {
    border-radius: 0;

}

/*Btn with icon*/
.btn--with-icon {
    padding-right: 45px;
}
.btn__icon:before {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 0.9375em;
    margin-top: -7px;
    z-index: 1;

}
.btn__icon:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1px;
    width: 35px;
    border-top-right-radius: var(--btn-border-radius);
    border-bottom-right-radius: var(--btn-border-radius);
    border-right: 1px solid var(--color-primary-dark);
}
.btn-white .btn__icon:after {
    border-right: 1px solid var(--color-secondary);
}
.btn-secondary .btn__icon:after {
    border-right: 1px solid color(var(--color-secondary) b(+20%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
.btn--with-icon.btn-lg {
    padding-right: 55px;
}
.btn-lg>.btn__icon:after {
    width: 40px;
}
.btn--with-icon-left {
    padding-right: 14px;
    padding-left: 45px;
}
.btn--with-icon-left > .btn__icon:before {
    right: auto;
    left: 10px;
}
.btn--with-icon-left .btn__icon:after {
    left: 0;
    right: auto;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

/*Primary*/
.btn-primary {
    background: var(--color-primary);
    /*box-shadow: 0 0 1px var(--color-primary);*/
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background: color(var(--color-primary) b(+10%));
}
.btn-primary>.btn__icon:after {
    background: var(--color-primary-dark);
}

/*Secondary*/
.btn-secondary {
    background: var(--color-secondary);
    color: #fff;
}
.btn-secondary>.btn__icon:after {
    background: color(var(--color-secondary) b(+20%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
    color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
    background: color(var(--color-secondary) b(+20%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
    /*background: var(--color-primary);*/
    color: #fff;
}

/*Default*/
.btn-default {
    background: var(--color-info);
    color: var(--color-dark-grey);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active {
    background: var(--color-primary);
    color: var(--color-primary-comp);
    /*box-shadow: none;*/
}

/*Default--inverted*/
.btn-default--inverted {
    background: var(--color-dark-grey);
    color: var(--color-info);
}

/*White*/
.btn-white {
    background: #ffffff;
    color: var(--color-primary);
    /*box-shadow: 0 0 1px #ffffff;*/
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
    background-color: var(--color-secondary);
    color: var(--color-primary-comp);
}
.btn-white>.btn__icon {
    color: #ffffff;
}
.btn-white>.btn__icon:after {
    background-color: var(--color-secondary);
}

/*Btn Group Secondary*/
.btn-group>.btn-secondary:last-child:not(:first-child){
    border-right: 0px;
}
.btn-group .btn-secondary {
    background: var(--color-secondary);
    /*color: var(--color-primary);*/
    color: var(--btn-group-secondary-color);
    border-radius: 0px;
    border-right: 1px solid #fff;
}
.btn-group .btn-secondary:hover,
.btn-group .btn-secondary:focus,
.btn-group .btn-secondary:active,
.btn-group .btn-secondary.active {
    background: var(--color-primary);
    color: #ffffff;
}


/*Link Btn*/
.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}

/*Hovers*/
.btn-hover-danger:hover {
    background: var(--color-error);
    color: #ffffff;
}

.btn-no-styling {
    background: none;
    padding: 0;
    color: inherit;
}

/*Btn Group*/
.btn-group .btn+.btn {
    margin-left: 1px;
}
.btn-group-vertical > .btn-icon-only.btn-icon-only.btn-icon-only {
    width: 37px;
    padding: 0;
    height: 37px;
    line-height: 0;
    text-align: center;
    border-radius: 0;
    font-size: 20px;
}
.btn-group-vertical > .btn-icon-only>.icon {
    vertical-align: middle;
}

/*Btn Slide*/
.btn-slide {
    line-height: 2.33;
    width: 100%;
}


.icon-btn {
    height: 44px;
    width: 44px;
    background-color: var(--color-primary);
    color: #ffffff;
    position: relative;
}
.icon-btn .icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.1em;
}
.icon-btn .icon-btn__icon.icon-footer-youtube,
.icon-btn .icon-btn__icon.icon-footer-facebook,
.icon-btn .icon-btn__icon.icon-footer-instagram {
    font-size: 1.75em;
}
