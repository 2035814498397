:root {
    --navbar-height: 100px;
    --navbar-height-affix: 50px;
    --navbar-height-smaller-screens: 75px;
    --navbar-border-width: 3px;
    --navitem-border-color: var(--color-info);
    --navbar-text-color-hover: var(--color-primary-comp);
    --navbar-text-color: var(--color-primary);
    --navbar-background: var(--color-secondary);
    --navbar-border-bottom-color: var(--color-secondary);
    --nav-item-hover-color: var(--color-primary);
}

@media screen and (min-width: 768px) {
    .page-wrapper {
        padding-top: var(--navbar-height);
    }
}
.navbar-main {
    margin-bottom: 0;
    background: var(--navbar-background);
    border: none;
    border-bottom: 3px solid var(--navbar-border-bottom-color);
    border-radius: 0;
    box-shadow: 0 0 5px rgba(0,0,0,.25);
    z-index: 100;
    /*promote to own comp layer for better chrome scrolling rendering*/
    transform: translateZ(0);
}
@media screen and (min-width: 768px) {
    .navbar-main {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: var(--navbar-height);
    }
}
.navbar-main>.container,
.navbar-main .navbar-header {
    height: 100%;
}
.navbar-main .navbar-header>.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
}
.navbar-main .navbar-header>.navbar-brand:before {
    /*Center brand img vertical*/
    content: '';
    display: inline-block;
    height: 100%;
    font-size: 0;
    vertical-align: middle;
}
.navbar-main .navbar-header>.navbar-brand>img {
    max-height: 100%;
    /*width: auto;*/
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 3px;
}
@media screen and (min-width: 768px) {
    .navbar-nav-main {
        float: right;
    }

    /***Nav Links***/
    .navbar__item>a,
    .navbar-nav-main>li>a {
        display: block;
        position: relative;
        padding: 0 18px;
        height: var(--navbar-height);
        line-height: var(--navbar-height);
        font-family: var(--font-bold-family);
        font-weight: var(--font-bold-weight);
        border-right: 1px solid var(--navitem-border-color);
        color: var(--navbar-text-color);
    }

    .navbar__item:first-child>a,
    .navbar-nav-main>li:first-child>a {
        border-left: 1px solid var(--navitem-border-color);
    }
    .navbar__item:hover>a,
    .navbar__item>a:focus,
    .navbar__item.active>a,
    .navbar-nav-main>li:hover>a,
    .navbar-nav-main>li>a:focus,
    .navbar-nav-main>li.active>a {
        background-color: var(--nav-item-hover-color);
        color: var(--navbar-text-color-hover);
    }

    /*Border Bottom*/
    .navbar-border-bottom .navbar__item>a:after,
    .navbar-border-bottom .navbar-nav-main>li>a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: -1px;
        height: 0;
        border-bottom: var(--navbar-border-width) solid var(--color-secondary);
    }
    .navbar-border-bottom .navbar__item:first-child>a:after,
    .navbar-border-bottom .navbar-nav-main>li:first-child>a:after {
        left: -1px;
    }
    .navbar-border-bottom .navbar__item:hover>a:after,
    .navbar-border-bottom .navbar__item>a:focus:after,
    .navbar-border-bottom .navbar__item.active>a:after,
    .navbar-border-bottom .navbar-nav-main>li:hover>a:after,
    .navbar-border-bottom .navbar-nav-main>li>a:focus:after,
    .navbar-border-bottom .navbar-nav-main>li.active>a:after {
        border-bottom: var(--navbar-border-width) solid var(--color-primary-dark);;
        left: -1px;
    }

    /*Specials*/
    .navbar__item--additional.navbar__item--additional>a {
        border: none;
    }
    .navbar__item--additional>a {
        padding-left: 10px;
        padding-right: 10px;
    }
    .navbar__item--additional>a>span {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 39px;
        text-align: center;
        overflow: hidden;
        vertical-align: middle;
    }

    /*Search*/
    .navbar__search.navbar__search {
        position: static;
    }
    .navbar__search>a {
        font-size: 20px;
    }
    .navbar__search-form {
        position: absolute;
        /*bottom: 100%;*/
        width: 100%;
        /*height: 100%;*/
        top: 0;
        bottom: var(--navbar-border-width);
        left: 0;
        background: var(--navbar-background);
        z-index: 1;
        will-change: transform;
        /*fallback for ie9*/
        transform: translateY(-100%);
        /*transition: transform 140ms cubic-bezier(0.000, 0.615, 1.000, 1.120);;*/
        transition: opacity 140ms ease;
        opacity: 0;

        /*background: red;*/
    }
    .navbar__search-group {
        margin-right: 26px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -20px;
        color: #c3cbd4;
        max-width: 500px;
    }
    .navbar__search-group>.form-control-wrapper {
        height: 100%;
    }
    .navbar__search-group>.form-control-wrapper>input {
        color: #c3cbd4;
        height: 100%;
        border: 2px solid #c3cbd4;
        border-radius: 20px 0 0 20px;
        box-shadow: none;
        padding-right: 50px;
    }
    .navbar__search-group>.form-control-wrapper>input::-ms-clear {
        display: none;
    }
    .navbar__search-group>.form-control-wrapper>.form-control-icon {
        height: 40px;
        padding: 5px 10px 0 10px;
        font-size: 20px;
        margin-top: 0;
        top: 0;
        z-index: 3;
        pointer-events: auto;
    }
    .navbar__search-group>.input-group-btn {
        height: 100%;
    }
    .navbar__search-group>.input-group-btn>.btn {
        background: #c3cbd4;
        color: #ffffff;
        height: 100%;
        font-size: 18px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 39px;
    }
    .navbar__search-group>.input-group-btn>.btn>.icon {
        vertical-align: middle;
    }

    /*open*/
    .navbar-main__wrapper.is-search-open{
        position: relative;
    }
    .navbar-main__wrapper.is-search-open .navbar__search-form {
        transform: none;
        opacity: 1;
    }

    /*Affix*/
    .navbar-main.affix {
        height: var(--navbar-height-affix);
        position: fixed;
    }
    .navbar-main.affix .navbar-nav-main>li>a,
    .navbar-main.affix .navbar__item>a {
        height: var(--navbar-height-affix);
        line-height: var(--navbar-height-affix);
    }
    .navbar-main.affix .navbar-header>.navbar-brand>img {
        /*width: auto;*/
        /*-webkit-backface-visibility: hidden;*/
        height: 100%;
    }
    .navbar-main.affix .navbar__item--additional>a>span {
        width: 32px;
        height: 32px;
        line-height: 31px;
    }
    .navbar-main.affix .navbar__item--additional>a>.icon {
        font-size: .8em;
    }
    .navbar-main.affix .navbar__search>a {
        font-size: 17px;
    }

    /*Dropdown (see navbar-main-dropdown.css for more)*/
    .main-nav__dropdown {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .navbar-main .container {
        padding: 0 20px;
    }
    .page-wrapper,
    .no-header-bar .page-wrapper {
        padding-top: var(--navbar-height-smaller-screens);
    }
    .navbar-main {
        height: var(--navbar-height-smaller-screens);
    }
    .navbar__item>a,
    .navbar-nav-main>li>a {
        /*font-size: 18px;*/
        padding: 0 10px;
        height: var(--navbar-height-smaller-screens);
        line-height: var(--navbar-height-smaller-screens);
    }
}
