.floated-item {
    margin-bottom: 0.5em;
}
@media screen and (max-width: 767px) {
    .floated-item {
        /*override bootstraps important*/
        float: none !important;
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
}
@media screen and (min-width: 768px) {
    .floated-item.pull-left {
        margin-right: 25px;
    }
    .floated-item.pull-right {
        margin-left: 25px;
    }
}