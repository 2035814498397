.accordion {
    border-bottom: 1px solid var(--color-info-dark);
}
.accordion__heading {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 35px;
}
.accordion__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 22px;
    line-height: 1;
    margin-top: -11px;
    transform: rotate(180deg); /*fallback for ie9*/
    transform: rotate(180deg) translateZ(0);
    transition: transform 120ms ease;
}
.collapse__icon {
    transform: rotate(180deg); /*fallback for ie9*/
    transform: rotate(180deg) translateZ(0);
    transition: transform 120ms ease;
}
.collapsed .accordion__icon,
.collapsed .collapse__icon{
    transform: rotate(0deg); /*fallback for ie9*/
    transform: rotate(0deg) translateZ(0);
}

@media screen and (min-width: 768px) {
    .collapse-xs-only {
        display: block;
        visibility: visible;
    }
}


/* default bootstrap collapse styles */
.collapse {
    display: none;
}
.collapse.in {
    display: block;
}
tr.collapse.in {
    display: table-row;
}
tbody.collapse.in {
    display: table-row-group;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}