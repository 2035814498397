html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
}

body {
    position: relative;
}
.container {
    max-width: 1240px;
    width: 100%;
}
.row {
    margin-left: -10px;
    margin-right: -10px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
}
.page-wrapper {
    background: #ffffff;
    position: relative;
    z-index: 1;
}
.modal-open .page-wrapper {
    position: static; /*fix for bootstrap modals*/
}

.text-right{
    float: right;
}
@media screen and (min-width: 768px) {
    .container {
        padding-left: 45px;
        padding-right: 45px;
    }
}
@media screen and (min-width: 1200px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
}