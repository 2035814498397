.status {
    height: 24px;
    width: 24px;
    display: inline-block;
    border-radius: 100%;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
    vertical-align: sub;
}
.status>.icon {
    vertical-align: middle;
    font-size: 12px;
}
.status>.icon-check {
    font-size: 20px;
}
.status>.icon-vorbereitung {
    font-size: 14px;
}
.legend .status {
    vertical-align: middle;
    margin-right: 10px;
}

.status--track-very-easy{
    background-color: var(--color-success);
}

.status--track-easy {
    background-color:  #0064b4;
}
.status--track-medium {
    background-color: var(--color-error);
}
.status--track-difficult {
    background-color: #000;
}
.status--track-route {
    vertical-align: top;
    border-radius: 0;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    background-color: var(--color-error);
}