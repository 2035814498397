@media screen and (min-width: 768px) {
    .quickfinder-slide {
        position: relative;
    }
    .quickfinder-slide:before {
        position: absolute;
        bottom: -100px;
        right: 0;
        font-size: 770px;
        color: #fff;
        opacity: .5;
        content: "\E03C";
        font-family: iconfont;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
    }
}
