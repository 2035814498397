:root {
    --partner-slide-bg: var(--color-primary);
}
.partner-slide {
    background: var(--partner-slide-bg);
    color: #fff;
}
.partner-slide .box--xs {
    height: 140px;
}
.divider {
    border-bottom: 1px solid var(--color-primary-light);
}
.partner-slide .arrange__item img  {
    width: auto;
    height: auto;
}
.partner-slide a {
    color: #b5c7af;
}
.partner-slide a:hover {
    color: #fff;
}

.partner-slide a .icon{
    position: relative;
    top: 2px;
}

@media screen and (min-width: 768px){
    .partner-slide .arrange__item img {
        width: 100%;
        height: auto;
    }
}