.hover-zoom {
    position: relative;
    transform: translateZ(0);
}
.hover-zoom__overlay,
.hover-zoom__overlay:after{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    transition: opacity 140ms ease;
}
.hover-zoom__overlay:after {
    content: '';
    opacity: .3;
    background: #000000;
    visibility: visible;
}
.hover-zoom__overlay>.icon {
    position: absolute;
    z-index: 1;
    color: #ffffff;
    font-size: 45px;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -22px;
}

/*Hover*/
.hover-zoom__target:hover .hover-zoom__overlay,
.hover-zoom__target:active .hover-zoom__overlay,
/*todo remove isclickable from css and html templates*/
.isClickable:hover .hover-zoom__overlay,
.js-lightbox:hover .hover-zoom__overlay,
.hover-zoom:hover .hover-zoom__overlay,
.hover-zoom:active .hover-zoom__overlay {
    visibility: visible;
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .hover-zoom__overlay>.icon {
        font-size: 74px;
        margin-top: -37px;
    }
}