@font-face {
    font-family: "iconfont";
    src: url('/static/icons/planai/font/iconfont.eot?#iefix') format('eot'),
    url('/static/icons/planai/font/iconfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /*display: inline-block;*/
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*transform: translate(0, 0);*/
}

.icon-lg {
                     font-size: 1.3333333333333333em;
                     line-height: 0.75em;
                     vertical-align: -15%;
                 }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                     width: 1.2857142857142858em;
                     text-align: center;
                 }

.icon-ul {
                     padding-left: 0;
                     margin-left: 2.14285714em;
                     list-style-type: none;
                 }
.icon-ul > li {
                     position: relative;
                 }
.icon-li {
                     position: absolute;
                     left: -2.14285714em;
                     width: 2.14285714em;
                     top: 0.14285714em;
                     text-align: center;
                 }
.icon-li.icon-lg {
                                         left: -1.85714286em;
                                     }

.icon-rotate-90 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                     -webkit-transform: rotate(90deg);
                     -ms-transform: rotate(90deg);
                     transform: rotate(90deg);
                 }
.icon-rotate-180 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
                     -webkit-transform: rotate(180deg);
                     -ms-transform: rotate(180deg);
                     transform: rotate(180deg);
                 }
.icon-rotate-270 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                     -webkit-transform: rotate(270deg);
                     -ms-transform: rotate(270deg);
                     transform: rotate(270deg);
                 }
.icon-flip-horizontal {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
                     -webkit-transform: scale(-1, 1);
                     -ms-transform: scale(-1, 1);
                     transform: scale(-1, 1);
                 }
.icon-flip-vertical {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
                     -webkit-transform: scale(1, -1);
                     -ms-transform: scale(1, -1);
                     transform: scale(1, -1);
                 }
:root .icon-rotate-90,
                       :root .icon-rotate-180,
                                              :root .icon-rotate-270,
                                                                     :root .icon-flip-horizontal,
                                                                                            :root .icon-flip-vertical {
                                                                                                                       filter: none;
                                                                                                                   }

.icon-angebote:before { content: "\E001" }
.icon-arrow-slider-big_left:before { content: "\E002" }
.icon-arrow-slider-big_right:before { content: "\E003" }
.icon-arrow-slider-small_left:before { content: "\E004" }
.icon-arrow-slider-small_right:before { content: "\E005" }
.icon-arrow:before { content: "\E006" }
.icon-arrow_buchen:before { content: "\E007" }
.icon-arrow_down:before { content: "\E008" }
.icon-babylift:before { content: "\E009" }
.icon-blog:before { content: "\E00A" }
.icon-calendar:before { content: "\E00B" }
.icon-car:before { content: "\E00C" }
.icon-check:before { content: "\E00D" }
.icon-download:before { content: "\E00E" }
.icon-einseil-umlaufbahn:before { content: "\E00F" }
.icon-events:before { content: "\E010" }
.icon-foerderband:before { content: "\E011" }
.icon-fruehling:before { content: "\E012" }
.icon-galerie:before { content: "\E013" }
.icon-gondel:before { content: "\E014" }
.icon-herbst:before { content: "\E015" }
.icon-infos:before { content: "\E016" }
.icon-konsole_anlagen:before { content: "\E017" }
.icon-konsole_share:before { content: "\E018" }
.icon-konsole_sun:before { content: "\E019" }
.icon-konsole_tickets:before { content: "\E01A" }
.icon-konsole_webcam:before { content: "\E01B" }
.icon-liste:before { content: "\E01C" }
.icon-locator:before { content: "\E01D" }
.icon-map-pin:before { content: "\E01E" }
.icon-mashup_blog:before { content: "\E01F" }
.icon-mashup_facebook:before { content: "\E020" }
.icon-mashup_google-plus:before { content: "\E021" }
.icon-mashup_instagram:before { content: "\E022" }
.icon-mashup_youtube:before { content: "\E023" }
.icon-mobile_menue:before { content: "\E024" }
.icon-mobile_menue_close:before { content: "\E025" }
.icon-news:before { content: "\E026" }
.icon-pano:before { content: "\E027" }
.icon-plane:before { content: "\E028" }
.icon-play:before { content: "\E029" }
.icon-schlepper:before { content: "\E02A" }
.icon-search:before { content: "\E02B" }
.icon-sessellift_acht:before { content: "\E02C" }
.icon-sessellift_drei:before { content: "\E02D" }
.icon-sessellift_sechs:before { content: "\E02E" }
.icon-sessellift_vier:before { content: "\E02F" }
.icon-snow:before { content: "\E030" }
.icon-social_blog:before { content: "\E031" }
.icon-social_facebook:before { content: "\E032" }
.icon-social_flickr:before { content: "\E033" }
.icon-social_google-plus:before { content: "\E034" }
.icon-social_instagram:before { content: "\E035" }
.icon-social_yoututbe:before { content: "\E036" }
.icon-sommer:before { content: "\E037" }
.icon-sommercard:before { content: "\E038" }
.icon-star:before { content: "\E039" }
.icon-sun:before { content: "\E03A" }
.icon-tragseilbahn:before { content: "\E03B" }
.icon-trennelement:before { content: "\E03C" }
.icon-vorbereitung:before { content: "\E03D" }
.icon-warenkorb:before { content: "\E03E" }
.icon-webcam:before { content: "\E03F" }
.icon-winter:before { content: "\E040" }
.icon-plus:before { content: "\E041" }
.icon-arrow-right-bold:before { content: "\E042" }
.icon-arrow-right:before { content: "\E043" }
.icon-arrow-down:before { content: "\E044" }
.icon-arrow-left:before { content: "\E045" }
.icon-close:before { content: "\E046" }
.icon-info:before { content: "\E047" }
.icon-minus:before { content: "\E048" }
.icon-pressebilder:before { content: "\E049" }
.icon-pressetexte:before { content: "\E04A" }
.icon-phone:before { content: "\E04B" }
.icon-kalender:before { content: "\E04C" }
.icon-konsole_fotos:before { content: "\E04D" }
.icon-sessellift_zwei:before { content: "\E04E" }
.icon-oeffnungszeiten:before { content: "\E04F" }
.icon-tickets:before { content: "\E050" }
.icon-sessellift:before { content: "\E051" }
.icon-anlagen:before { content: "\E052" }
.icon-train:before { content: "\E053" }
.icon-hit-der-woche:before { content: "\E054" }
.icon-kulinarik:before { content: "\E055" }
.icon-webshop:before { content: "\E056" }
.icon-angebote-info-icon:before { content: "\E057" }
.icon-familie:before { content: "\E060" }
.icon-mag:before { content: "\E061" }
.icon-social_twitter:before { content: "\E070" }
.icon-group:before { content: "\E072" }
.icon-footer-facebook:before { content: "\E080" }
.icon-footer-youtube:before { content: "\E081" }
.icon-footer-instagram:before { content: "\E082" }