.webshop-item {
    margin-bottom: 25px;
}
.webshop-item .arrange {
    position: relative;
}
.webshop-item__description {
    padding: 15px 25px 20px 25px;
    position: relative;
    z-index: 1;
}

#cart h6>span.icon{
    font-size: 30px;
}



@media screen and (min-width: 768px) {
    .webshop-item__formbar .form-group {
        margin-bottom: 0;
    }
}

/*Submitted*/
.webshop-item__submittedMsg {
    position: relative;
}

.webshop-item__submittedMsg h5>span{
    position: relative;
    top: 3px;
}


.webshop-item__description h5{
    position: relative;
    z-index: 1;
    color: var(--color-primary);
}

.webshop-item__submittedMsg-container {
    border: 2px solid #f2f2f2;
}

.is-submitted .webshop-item__submittedMsg-container{
    border: none;
}

.is-submitted .webshop-item__description p{
    display: none;
}

/*.webshop-item__submittedMsg-container:after {*/
    /*content: "\E03C";*/
    /*font-family: iconfont;*/
    /*color: #f3f3f3;*/
    /*font-size: 170px;*/
    /*position: absolute;*/
    /*right: 30px;*/
    /*top: 12px;*/
    /*z-index: 0;*/
    /*opacity: .7;*/
    /*line-height: 170px;*/
/*}*/

.webshop-item__submittedMsg {
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    transition: visibilty 0.0001ms, opacity 120ms ease;
}


.webshop-item__submittedMsg:before {
    z-index: -1;
    content: '';
    background: #fff;
    border: 3px solid var(--color-secondary);
    opacity: .85;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: visibilty 0.0001ms 120ms, opacity 120ms ease;
}

.webshop-item__submittedMsg .absolute-centered{
    z-index: 1;
}

.webshop-item.is-submitted .webshop-item__submittedMsg {
    visibility: visible;
    opacity: 1;
}
.webshop-item__submittedMsg-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 13px;
    font-size: 13px;
    opacity: .7;
    cursor: pointer;
    display: none;
}

.is-submitted .webshop-item__submittedMsg-close{
    display: block;
}

.webshop-item__submittedMsg-close:hover {
    opacity: 1;
}

.nav-progress>li{
    float: left;
}

.nav-progress>li>a,
.nav-progress>li>span {
    position: relative;
    height: 37px;
    line-height: 38px;
    padding: 0 10px;
    background: #000;
    background: linear-gradient(to right,#ebf0f3 0,#dae3ec 100%);
}

.nav-progress>li.active>a, .nav-progress>li.active>span {
    background: #008244;
    background: linear-gradient(to right,#01a356 0,#008244 100%);
    color: #fff;
}

.nav-progress>li+li>a, .nav-progress>li+li>span {
    padding-left: 24px;
}

.nav-progress>li.nav-progress__item--icon>a{
    background: var(--color-primary);
    color: #fff;
}

.nav-progress>li>a:after,
.nav-progress>li>span:after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18.5px 0 18.5px 14px;
    border-color: transparent transparent transparent #dae3ec;
    z-index: 1;
}

.nav-progress>li.active>a:after, .nav-progress>li.active>span:after,
.nav-progress>li.nav-progress__item--icon>a:after{
    border-left-color: var(--color-primary);
}


.accordion__heading{
    cursor: pointer;
}

@media screen and (min-width: 768px){
    .webshop-item__submittedMsg:after {
        content: "\E03C";
        font-family: iconfont;
        color: #F3F3F3;
        font-size: 170px;
        position: absolute;
        right: 50px;
        top: 16px;
        z-index: 0;
        opacity: 0.7;
        line-height: 170px;
    }
    .webshop-item__submittedMsg-container {
        position: static;
    }
    .webshop-item__submittedMsg {
        margin-left: 25%;
    }

}

@media screen and (min-width: 768px) and (max-width: 991px){
    .webshop-item__submittedMsg:after {
        font-size: 145px;
        right: 50px;
        top: 11px;
        line-height: 145px;
    }
}

@media screen and (max-width: 767px){
    #cart .accordion__heading h6{
        margin: 0;
    }

    .link-versandkosten{
        margin-bottom: 9px;
        display: block;
    }

    .is-submitted .webshop-item__submittedMsg-container{
        border: 3px solid var(--color-secondary)
    }

    .is-submitted .webshop-item__submittedMsg{
        position: relative;
        padding: 20px 0;
    }

    .is-submitted .webshop-item__submittedMsg .absolute-centered{
        position: relative;
        transform: translateY(0);
    }

    .is-submitted .webshop-item__submittedMsg:before{
        border: none;
    }
    .is-submitted .webshop-item__description{
        padding-bottom: 0;
    }

    .webshop-item__formbar .h2{
        margin-top: 5px;
    }
}