:root {
    --decorated-hr-color: var(--color-primary);
}
.decorated-hr {
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
    color: var(--decorated-hr-color);
}


.decorated-hr__icon {
    line-height: 1.27;
    position: relative;
    font-size: 55px;
    padding: 0 15px;
}

.decorated-hr__left,
.decorated-hr__right {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 1000px;
    height: 12px;
    margin-top: -1px;
    color: inherit;
    border-top: 0px; /*hr.css overwrite*/
}
.decorated-hr__left {
    border-top: 2px solid;
}
.decorated-hr__right{
    border-bottom: 2px solid;
    margin-top: -11px;
    left: auto;
    right: 100%;
}